import Link from 'next/link';
import React, { FC, MouseEvent } from 'react';

import { Link as LinkT } from '@/types/views/generic';

interface CustomLinkProps {
  href: LinkT['href'];
  as?: LinkT['as'];
  isExternal?: LinkT['isExternal'];
  passHref?: boolean;
  className?: string;
  download?: boolean;
  onClick?: (e: MouseEvent) => void;
}

// Remove paths which shouldn't cause re-render website
const PATHS = [
  '^/red-session',
  '^/card',
  '^/mediacenter',
  '^/energy-web',
  '^/login$',
  '^/profile$',
  '^/profile/tickets$',
  '^/profile/card$',
  '^/profile/one$',
  '^/account$',
  '^/account/tickets$',
  '^/account/card$',
  '^/account/one$',
  '^/music-award',
];

const pathRegExps = PATHS.map<RegExp>((pattern) => new RegExp(pattern));

const CustomLink: FC<CustomLinkProps> = ({
  as,
  href,
  isExternal = false,
  passHref = false,
  children,
  className = '',
  download,
  onClick,
}) =>
  !pathRegExps.some((regExp) => regExp.test(href)) && !download ? (
    <Link href={href} as={as} passHref={passHref}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        target={isExternal ? '_blank' : '_self'}
        className={className}
        onClick={onClick}
      >
        {children}
      </a>
    </Link>
  ) : (
    /* eslint-disable-next-line jsx-a11y/anchor-is-valid */
    <a
      href={href}
      target={isExternal ? '_blank' : '_self'}
      className={className}
      onClick={onClick}
      rel="noreferrer"
      download={download}
    >
      {children}
    </a>
  );

export default CustomLink;
