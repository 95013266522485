import cls from 'classnames';
import React, { FC, MouseEventHandler, ReactNode } from 'react';

import {
  ButtonColor,
  ButtonColorEAIR,
  ButtonColorEDS,
  ButtonColorELS,
  ButtonColorESN,
  ButtonColorGame,
  ButtonSize,
  ButtonVariant,
  ButtonVariantEAIR,
  ButtonVariantEDS,
  ButtonVariantELS,
  ButtonVariantESN,
  ButtonVariantGame,
} from '@/types/views/generic';

import { buttonSizes, buttonVariants } from './styles';

const styles = { buttonSizes, buttonVariants };

export type ButtonMain = {
  variant?: ButtonVariant;
  color?: ButtonColor;
};

export type ButtonEAIR = {
  variant?: ButtonVariantEAIR;
  color?: ButtonColorEAIR;
};

export type ButtonESN = {
  variant?: ButtonVariantESN;
  color?: ButtonColorESN;
};

export type ButtonEDS = {
  variant?: ButtonVariantEDS;
  color?: ButtonColorEDS;
};

export type ButtonELS = {
  variant?: ButtonVariantELS;
  color?: ButtonColorELS;
};

export type ButtonGame = {
  variant?: ButtonVariantGame;
  color?: ButtonColorGame;
};

export type NormalButtonProps = {
  text: ReactNode;
  type?: 'button' | 'submit' | 'reset';
  size?: ButtonSize;
  disabled?: boolean;
  className?: string;
  classNameColor?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
} & (ButtonMain | ButtonEAIR | ButtonESN | ButtonEDS | ButtonELS | ButtonGame);

const NormalButton: FC<NormalButtonProps> = ({
  text,
  color = 'red',
  size = 'M',
  variant = 'default',
  type = 'button',
  disabled = false,
  className = '',
  onClick,
}) => {
  const isEventButtonVariant =
    variant === 'eair' || variant === 'esn' || variant === 'eds';

  return (
    <button
      disabled={disabled}
      className={cls(
        styles.buttonSizes[isEventButtonVariant ? 'S' : size],
        styles.buttonVariants(variant, color),
        'text-body2 font-semibold',
        className,
      )}
      type={type}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default NormalButton;
