import cls from 'classnames';
import { FC } from 'react';

import EnergyLogo from '@/components/assets/energy-logo';
import CustomLink from '@/components/custom-link';

const energyLogoCommonClasses = 'xl:min-w-max w-auto md:h-10 xl:w-auto xl:h-10';

interface EnergyLogoLinkProps {
  isEventTheme: boolean;
  isNavbarVisible: boolean;
  onNavClose?: () => void;
  className?: string;
}

const EnergyLogoLink: FC<EnergyLogoLinkProps> = ({
  isEventTheme,
  isNavbarVisible,
  onNavClose,
  className = '',
}) => (
  <CustomLink
    href="/"
    onClick={onNavClose}
    className={cls(
      'flex-grow-0 xl:flex-shrink-0 xl:inline pr-4',
      isEventTheme ? '2xl:fixed 2xl:-ml-56 xl:flex-0' : 'xl:flex-1',
      isEventTheme && !isNavbarVisible ? '2xl:top-28' : '2xl:top-6',
      className,
    )}
  >
    <EnergyLogo
      className={cls('hidden md:block fill-red', energyLogoCommonClasses)}
    />
    <EnergyLogo
      className={cls('md:hidden fill-red', energyLogoCommonClasses)}
      noText
    />
  </CustomLink>
);

export default EnergyLogoLink;
