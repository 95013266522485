import cls from 'classnames';
import React, { FC } from 'react';

import styles from './styles';

interface SkeletonProps {
  className?: string;
}

const Skeleton: FC<SkeletonProps> = ({ className = '' }) => (
  <div className={cls(styles.wrapper, className)} />
);

export default Skeleton;
